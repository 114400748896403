<template>
  <div>
    <general-table
      ref="CouponTable"
      :api-url="APIURL"
      :add-type="addType"
      :add-multi-type="true"
      :add-type-one="'Single'"
      :add-type-two="'Bulk'"
      :add-type-one-page="addComponentName"
      :add-type-two-page="addComponentTwoName"
      :add-component-name="addComponentName"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="false"
      :columns="columns"
      :guard="guard"
      :type="type"
      :selectable="false"
      :search-box="true"
    >
      <template #thead-top>
        <b-tr>

          <b-td
            class="border-0 p-0 pb-2"
            colspan="2"
          >
            <b-form-datepicker
              v-model="filter.start_date"
              placeholder="Start Date"
              reset-button
              close-button
              locale="en-GB"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-td>
          <b-td
            class="border-0 p-0 pb-2"
            colspan="2"
          >
            <b-form-datepicker
              v-model="filter.end_date"
              :min="filter.start_date"
              placeholder="End Date"
              reset-button
              close-button
              locale="en-GB"
              class="ml-1"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-td>
          <b-td
            class="border-0 p-0 pb-2 px-2"
            colspan="2"
          >

            <b-link
              class="ml-1"
              title="Export"
              :href="downloadFile"
            >
              <feather-icon
                icon="DownloadCloudIcon"
                size="23"
                class="text-success"
              />
            </b-link>
          </b-td>
        </b-tr>
      </template>
      <template #cell(type)="data">
        {{ getOfferTypes(data.item.type) }}
      </template>
      <template #cell(user_count_user)="data">
        {{ getTypes(data.item.user_count_user) }}
      </template>
    </general-table>

  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  setup() {
    const filter = ref({})

    return {

      filter,

    }
  },
  data() {
    return {
      APIURL: 'promoCodes',
      addType: 'Add PromoCode',
      addComponentName: 'add-promo-code',
      addComponentTwoName: 'add-bulk-promo-code',
      type: 'page',
      guard: 'promo',
      columns: [
        { key: 'name', label: 'Promo Code' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'type', label: 'Offer Type' },
        { key: 'amount', label: 'Amount' },
        { key: 'min', label: 'Min Order' },
        { key: 'max', label: 'Max Discount' },
        { key: 'used_count', label: 'Used Count' },
        { key: 'user_count_user', label: 'Multi use' },
        { key: 'expire', label: 'Expired at' },
      ],
      product_gifts: [],
      multi: [
        { text: 'Yes', value: '1' },
        { text: 'No', value: '0' },
      ],
      types: '',
    }
  },
  computed: {

    apiUrl() {
      let base = 'promoCodes'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
    downloadFile() {
      let base = 'https://v2.zerocash.co/promo_codes?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
  },

  methods: {

    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'Active') return 'success'

      return 'danger'
    },
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
    getTypes(type) {
      if (type === 1) {
        return 'yes'
      } if (type === 0) {
        return 'No'
      }
      return 'yes'
    },
    getOfferTypes(type) {
      if (type === 'P') {
        return 'Percentage'
      } if (type === 'F') {
        return 'Fixed'
      }
      return 'Fixed'
    },
  },
}
</script>

<style>

</style>
